import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import ChatbotPage from './components/ChatbotPage';

function App() {
  return (
    <Router>
      <nav>
        <ul>
          <li>
            <Link to="/"></Link>
          </li>
          <li>
            <Link to="/admin"></Link>
          </li>
        </ul>
      </nav>
      <Routes>
        <Route path="/" element={<ChatbotPage />} />
      </Routes>
    </Router>
  );
}

export default App;
