import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import closeIcon from '../assets/icons.png';
import userAvatar from '../assets/user.png';
import botAvatar from '../assets/bot.jpg';
import sendIcon from '../assets/send.png';
import scrollIcon from '../assets/arrow.png';
import micIcon from '../assets/mic.png';
import startSound from '../assets/start.mp3'; 
import footerImage from '../assets/footer.png'; 

function ChatbotPage() {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [stopTyping, setStopTyping] = useState(false);
  const [isResponseActive, setIsResponseActive] = useState(false);
  const [isIntroComplete, setIsIntroComplete] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const messagesEndRef = useRef(null);
  const scrollRef = useRef(null);
  const speechRecognitionRef = useRef(null);
  const audioRef = useRef(new Audio(startSound)); 
  const typingIntervalRef = useRef(null); 
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const userScrollingTimeoutRef = useRef(null);
  const [isAutoScrollAllowed, setIsAutoScrollAllowed] = useState(true); 

  const closeChat = () => {
    window.parent.postMessage("closeChatbot", "*");
  };

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      speechRecognitionRef.current = new SpeechRecognition();
      speechRecognitionRef.current.lang = 'en-US';
      speechRecognitionRef.current.interimResults = false;

      // Handle Opera and Safari-specific permissions
      const browser = navigator.userAgent.toLowerCase();
      if (browser.includes("safari") && !browser.includes("chrome") || browser.includes("opera")) {
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(() => {
            console.log("Microphone permission granted.");
          })
          .catch(error => {
            console.error("Microphone permission denied:", error);
            alert("Please allow microphone access for this feature to work.");
          });
      }

      speechRecognitionRef.current.onresult = (event) => {
        const speechToText = event.results[0][0].transcript;
        setInputValue(speechToText);
        handleSubmit(speechToText);
        stopRecording();
      };

      speechRecognitionRef.current.onerror = (event) => {
        console.error('Speech recognition error: ', event.error);
        alert('Error accessing microphone: ' + event.error);
        stopRecording();
      };

      speechRecognitionRef.current.onspeechend = () => {
        stopRecording();
      };
    }
  }, []);
  
  const toggleSpeechRecognition = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startSpeechRecognition();
    }
  };

  const startSpeechRecognition = () => {
    if (speechRecognitionRef.current) {
      document.getElementById('mic').classList.add('recording');
      const micShadow = document.querySelector('.mic-shadow');
      if (micShadow) micShadow.classList.add('expand-circle');

      audioRef.current.play()
        .then(() => {
          speechRecognitionRef.current.start();
          setIsRecording(true); // Set recording state to true
        })
        .catch(error => {
          console.error('Error playing sound:', error);
        });
    }
  };

  const stopRecording = () => {
    if (speechRecognitionRef.current) {
      speechRecognitionRef.current.stop();
    }
    setIsRecording(false); // Reset recording state to false
    document.getElementById('mic').classList.remove('recording');
    const micShadow = document.querySelector('.mic-shadow');
    if (micShadow) micShadow.classList.remove('expand-circle');
  };
 
  

  const handleSubmit = async (userMessageText) => {
    if (loading || isResponseActive) return;

    // Stop any ongoing intro message if the user sends a message
    if (!isIntroComplete) {
      clearInterval(typingIntervalRef.current);
      setStopTyping(true);
    }

    const userMessage = {
      text: userMessageText || inputValue,
      sender: 'user'
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInputValue("");
    
    setLoading(true);
    setIsResponseActive(true);

    try {
      const response = await fetch('/api/get', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
          'Cache-Control': 'no-cache,no-store,must-revalidate'
         },
        body: JSON.stringify({ message: userMessage.text, client_id: 1 })
      });

      if (response.ok) {
        const data = await response.json();
        const botMessage = { text: "", sender: 'bot' }; // Remove the time property here
        setMessages(prevMessages => [...prevMessages, botMessage]);
        typeMessage(formatResponse(data.response), () => {
          setLoading(false);
          setIsResponseActive(false);
        });
      } else {
        console.error("Server error:", response.statusText);
        setLoading(false);
        setIsResponseActive(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      setIsResponseActive(false);
    }
  };

const formatResponse = (response) => {
  const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

  // Replace URLs immediately as clickable links
  response = response.replace(urlPattern, (url) => {
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });

  return response
    .replace(/\*\*/g, '')
    .replace(/\n\*/g, '<br>&bull; ')
    .replace(/\n\-/g, '<br>&bull; ')
    .replace(/\n\d+\./g, (match) => '<br>' + match.trim());
};

const typeMessage = (message, callback) => {
  let index = 0;
  setIsTyping(true); // Bot is typing
  setStopTyping(false);

  const urlPattern = /\[.*?\]\(.*?\)/g;
  let matches = [...message.matchAll(urlPattern)];
  let currentUrl = matches.shift();

  typingIntervalRef.current = setInterval(() => {
    if (index < message.length) {
      if (stopTyping) {
        clearInterval(typingIntervalRef.current);
        setIsTyping(false);
        setStopTyping(false);
        return callback && callback();
      }

      setMessages((prevMessages) => {
        const newMessage = {
          ...prevMessages[prevMessages.length - 1],
          text: prevMessages[prevMessages.length - 1].text + message[index],
        };
        return [...prevMessages.slice(0, -1), newMessage];
      });

      // Skip URLs
      if (currentUrl && index === currentUrl.index) {
        const urlText = currentUrl[0];
        setMessages((prevMessages) => {
          const newMessage = {
            ...prevMessages[prevMessages.length - 1],
            text: prevMessages[prevMessages.length - 1].text + urlText,
          };
          return [...prevMessages.slice(0, -1), newMessage];
        });
        index += urlText.length;
        currentUrl = matches.shift();
      } else {
        index++;
      }

      // Auto-scroll only if user is near the bottom
      if (isAtBottom(scrollRef) && isAutoScrollAllowed) {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      clearInterval(typingIntervalRef.current);
      setIsTyping(false); // Typing finished
      if (callback) callback();
    }
  }, 80);
};



  useEffect(() => {
    const fetchWelcomeMessage = async () => {
      try {
        const response = await fetch('/api/get_intro');
        if (response.ok) {
          const data = await response.json();
          const welcomeMessage = { text: "", sender: 'bot' };
          setMessages((prevMessages) => [...prevMessages, welcomeMessage]);
          typeMessage(data.message, () => {
            setIsTyping(false);
            setIsIntroComplete(true);
          });
        } else {
          console.error("Failed to fetch welcome message:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching welcome message:", error);
      }
    };
    fetchWelcomeMessage();
  }, []);

  useEffect(() => {
    if (isIntroComplete) return;
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    const isUserAtBottom = scrollHeight - scrollTop === clientHeight;

    if (isUserAtBottom || isTyping) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isTyping, isIntroComplete]); 


  useEffect(() => {
    const handleScroll = () => {
      if (!scrollRef.current) return;
  
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 50;
  
      if (!isNearBottom) {
        // User manually scrolled, disable auto-scroll
        setIsUserScrolling(true);
        setIsAutoScrollAllowed(false);
  
        // Reset manual scrolling flag after 2 seconds of no activity
        clearTimeout(userScrollingTimeoutRef.current);
        userScrollingTimeoutRef.current = setTimeout(() => {
          setIsUserScrolling(false);
        }, 2000);
      } else {
        // User scrolled back to bottom, enable auto-scroll
        setIsUserScrolling(false);
        setIsAutoScrollAllowed(true);
      }
  
      // Show the "scroll-to-bottom" button if not near bottom
      setShowScrollButton(!isNearBottom);
    };
  
    const ref = scrollRef.current;
    ref.addEventListener("scroll", handleScroll);
  
    return () => {
      ref.removeEventListener("scroll", handleScroll);
      clearTimeout(userScrollingTimeoutRef.current);
    };
  }, []);
  
 
  const isAtBottom = (ref) => {
    if (!ref.current) return false;
    const { scrollTop, scrollHeight, clientHeight } = ref.current;
    return scrollHeight - scrollTop - clientHeight < 50; // Adjust threshold if needed
  };
  
  


  
  
  const micButtonStyle = isResponseActive ? { filter: 'grayscale(100%)' } : {};
  const sendButtonStyle = loading || isResponseActive ? { filter: 'grayscale(100%)' } : {};

  
  
  return (
    <div className="chat-container">
      <div className="card">
        <div className="card-header msg_head">
          <div className="d-flex bd-highlight">
            <div className="img_cont">
    {/* <img src={userImage} className="user_img" alt="Chakna Logo" />*/}              
    <span className="online_icon"></span>
            </div>
            <div className="ml-auto">
              <button type="button" className="close" aria-label="Close" id="closeChat" onClick={closeChat}>
                <img src={closeIcon} alt="Close" />
              </button>
            </div>
          </div>
        </div>
        <div id="messageFormeight" className="card-body msg_card_body" ref={scrollRef}>
          {messages.map((msg, index) => (
            <div key={index} className={`d-flex justify-content-${msg.sender === 'user' ? 'end' : 'start'} mb-4`}>
              {msg.sender === 'bot' && (
                <div className="img_cont_msg">
                  <img src={botAvatar} className="rounded-circle user_img_msg" alt="Bot" />
                </div>
              )}
              <div className={`msg_cotainer${msg.sender === 'user' ? '_send' : ''}`}>
                <div dangerouslySetInnerHTML={{ __html: msg.text }} />
              </div>
              {msg.sender === 'user' && (
                <div className="img_cont_msg">
                  <img src={userAvatar} className="rounded-circle user_img_msg" alt="User" />
                </div>
              )}
            </div>
          ))}
          {loading && !isTyping && (
            <div className="d-flex justify-content-start mb-4">
              <div className="img_cont_msg">
                <img src={botAvatar} className="rounded-circle user_img_msg" alt="Bot" />
              </div>
              <div className="msg_cotainer">
                <div className="loading-indicator"></div>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <div className="card-footer">
  <form id="messageArea" className="input-group" onSubmit={(e) => { e.preventDefault(); handleSubmit(inputValue); }}>
    <button type="button" id="mic" className="mic-button" onClick={toggleSpeechRecognition} style={micButtonStyle}>
      <div className="mic-shadow"></div>
      <img src={micIcon} alt="Microphone" style={{ width: '30px', height: '30px' }} />
    </button>
    <input
      type="text"
      id="text"
      name="msg"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      className="form-control type_msg"
      placeholder="Ask me anything..."
    />
    <button type="submit" className="input-group-text send_btn">
      <img src={sendIcon} alt="Send"
        onClick={() => handleSubmit(inputValue)} style={sendButtonStyle} disabled={loading || isResponseActive}
      />
    </button>
  </form>
  {showScrollButton && (
  <button
    onClick={() => {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
      setIsAutoScrollAllowed(true); // Explicitly re-enable auto-scroll
    }}
    className="scroll-to-bottom"
  >
    <img src={scrollIcon} alt="Scroll Down" />
  </button>
)}

              
              <div className="text-center footer">
                <a href="https://www.papermoon.in" target="_blank" rel="noopener noreferrer">
                  <img src={footerImage} alt="Footer" className="footer-img" />
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
    


    export default ChatbotPage;
